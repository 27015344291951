import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { IUser } from "../types";

export const getInternalToken = async (cognitoUser: CognitoUser | null, setGacUser: (user: IUser | null) => void): Promise<string | null> =>
  new Promise((resolve, reject) => {
    if (!cognitoUser) {
      setGacUser(null);
      resolve(null);
      return;
    }

    cognitoUser.getSession((err: Error | null, data: CognitoUserSession) => {
      if (err) {
        setGacUser(null);
        if (cognitoUser) cognitoUser.signOut();

        reject(err);
        return;
      }

      const accessToken = data.getIdToken();
      localStorage.setItem("GACTokenExpire", accessToken.getExpiration().toString());
      localStorage.setItem("GACToken", accessToken.getJwtToken());

      const token = accessToken?.getJwtToken();
      resolve(token ?? null);
    });
  });
