import { CSSObjectWithLabel } from "react-select";
import styled, { css } from "styled-components";

interface FlexBoxProps {
  direction?: "column" | "row";
  gap?: number;
  margin?: number | "auto";
  marginTop?: number | "auto";
  marginBottom?: number | "auto";
  marginLeft?: number | "auto";
  marginRight?: number | "auto";
  marginInline?: number;
  marginBlock?: number;
  padding?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingInline?: number;
  paddingBlock?: number;
  justifyContent?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around";
  alignItems?: "flex-start" | "center" | "flex-end";
  hideMobile?: number;
  relative?: boolean;
  columnOn?: number;
  columnOnMobile?: number;
  backgroundColor?: string;
  color?: string;
  flexWrap?: boolean;
  display?: string;
  width?: string;
  mobWidth?: string;
  maxWidth?: number;
  maxHeight?: number;
  radius?: number;
  flex?: string;
  border?: string;
  height?: number;
  overflow?: "hidden" | "auto";
  pointer?: boolean;
}

export const FlexBox = styled.div<FlexBoxProps>`
  display: ${({ display }) => display ?? "flex"};
  flex-direction: ${({ direction }) => direction ?? "row"};
  gap: ${({ gap = 0.5 }) => gap && `${gap}rem`};
  padding: ${({ padding }) => padding && `${padding}rem`};
  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}rem`};
  padding-bottom: ${({ paddingBottom }) => paddingBottom && `${paddingBottom}rem`};
  padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}rem`};
  padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}rem`};
  padding-inline: ${({ paddingInline }) => paddingInline && `${paddingInline}rem`};
  padding-block: ${({ paddingBlock }) => paddingBlock && `${paddingBlock}rem`};
  margin: ${({ margin }) => margin && `${margin}rem`};
  margin-top: ${({ marginTop }) => marginTop && `${marginTop}rem`};
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}rem`};
  margin-left: ${({ marginLeft }) => marginLeft && `${marginLeft}rem`};
  margin-right: ${({ marginRight }) => marginRight && `${marginRight}rem`};
  margin-inline: ${({ marginInline }) => marginInline && `${marginInline}rem`};
  margin-block: ${({ marginBlock }) => marginBlock && `${marginBlock}rem`};
  justify-content: ${({ justifyContent }) => justifyContent && justifyContent};
  align-items: ${({ alignItems }) => alignItems && alignItems};
  position: ${({ relative }) => relative && "relative"};
  background-color: ${({ backgroundColor }) => backgroundColor && backgroundColor};
  color: ${({ color }) => color && color};
  flex-wrap: ${({ flexWrap }) => flexWrap && "wrap"};
  width: ${({ width }) => width && width};
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}rem`};
  max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}rem`};
  border-radius: ${({ radius }) => radius && `${radius}rem`};
  flex: ${({ flex }) => flex && flex};
  border: ${({ border }) => border};
  height: ${({ height }) => height && `${height}rem`};
  overflow: ${({ overflow }) => overflow};
  cursor: ${({ pointer }) => pointer && "pointer"};

  ${({ columnOn }) =>
    columnOn &&
    css`
      @media (max-width: ${columnOn}px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
      }
    `};

  ${({ columnOnMobile }) =>
    columnOnMobile &&
    css`
      @media (max-width: ${columnOnMobile}px) {
        flex-direction: column;
      }
    `};

  ${({ hideMobile }) =>
    hideMobile &&
    css`
      @media (max-width: ${hideMobile}px) {
        display: none;
      }
    `};

  ${({ mobWidth }) =>
    mobWidth &&
    css`
      @media (max-width: 768px) {
        width: ${mobWidth};
      }
    `};
`;

interface TextProps {
  size?: number;
  line?: number;
  center?: boolean;
  color?: string;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginInline?: number;
  marginBlock?: number;
  padding?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingInline?: number;
  paddingBlock?: number;
  italic?: boolean;
  bold?: boolean;
  lineHeight?: number;
  underline?: string;
  letterSpacing?: number;
  hideMobile?: number;
  height?: number;
  maxWidth?: number;
  pre?: boolean;
}

export const Text = styled.p<TextProps>`
  font-size: ${({ size = 1 }) => size && `${size}rem`};
  text-align: ${({ center }) => center && "center"};
  color: ${({ color }) => color && color};
  margin: ${({ margin }) => margin && `${margin}rem`};
  margin-top: ${({ marginTop }) => marginTop && `${marginTop}rem`};
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}rem`};
  margin-left: ${({ marginLeft }) => marginLeft && `${marginLeft}rem`};
  margin-right: ${({ marginRight }) => marginRight && `${marginRight}rem`};
  margin-inline: ${({ marginInline }) => marginInline && `${marginInline}rem`};
  margin-block: ${({ marginBlock }) => marginBlock && `${marginBlock}rem`};
  padding: ${({ padding }) => padding && `${padding}rem`};
  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}rem`};
  padding-bottom: ${({ paddingBottom }) => paddingBottom && `${paddingBottom}rem`};
  padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}rem`};
  padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}rem`};
  padding-inline: ${({ paddingInline }) => paddingInline && `${paddingInline}rem`};
  padding-block: ${({ paddingBlock }) => paddingBlock && `${paddingBlock}rem`};
  font-style: ${({ italic }) => italic && "italic"};
  font-weight: ${({ bold }) => bold && "bold"};
  line-height: ${({ lineHeight }) => lineHeight && lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing && `${letterSpacing}rem`};
  height: ${({ height }) => height && `${height}rem`};
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}rem`};
  white-space: ${({ pre }) => pre && "pre"};

  ${({ underline }) =>
    underline &&
    css`
      border-bottom: 1px solid ${underline};
    `}

  ${({ line }) =>
    line &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${line};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `};

  ${({ hideMobile }) =>
    hideMobile &&
    css`
      @media (max-width: ${hideMobile}px) {
        display: none;
      }
    `}
`;

export const ReactSelectStyles = {
  input: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    padding: "0rem",
    width: "150px",
    zIndex: 200,
  }),
  container: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    padding: "0rem",
  }),
  valueContainer: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    padding: "0rem, 0.1rem",
    fontSize: "0.9rem",
  }),
  indicatorsContainer: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    padding: "0rem",
  }),
  dropdownIndicator: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    padding: "0.1rem",
  }),
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    minHeight: "1rem",
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    zIndex: 200,
  }),
  option: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    fontSize: "0.9rem",
    zIndex: 200,
    padding: "0.5rem",
  }),
  group: () => ({}),
  groupHeading: (baseStyle: CSSObjectWithLabel) => ({
    ...baseStyle,
    padding: "0.2rem",
  }),
};

export const ReactConsedSelectStyles = {
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontFamily: "Roboto Condensed",
    padding: 0,
  }),
  option: (styles: CSSObjectWithLabel) => ({
    ...styles,
    padding: "0.25rem",
  }),
  menuList: (styles: CSSObjectWithLabel) => ({
    ...styles,

    "::-webkit-scrollbar": {
      width: "0.4rem",
    },
  }),
};

export const GACButton = styled.button`
  outline: none;
  background: none;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.2rem;
  padding-inline: 0.2rem;
  padding-block: 0.25rem;
  border: 1px solid black;

  &:disabled {
    background-color: #ddd;
    color: #000;
    border-color: #ddd;
    pointer-events: none;
  }
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact; /* Firefox 97+, Safari 15.4+ */
`;

export const SlimButton = styled(GACButton)`
  height: 1.5rem;
  color: white;
  background-color: black;
  gap: 0.5rem;
  border: 0px solid black;
  padding: 0.5rem;
  align-items: center;
  font-size: 0.85rem;
`;

export const GreenSlimButton = styled(SlimButton)`
  background-color: green;
  color: white;
`;

export const MixerButton = styled(SlimButton)`
  width: 115px;
  text-transform: uppercase;
  padding: 0.2rem;
`;

export const MixerLabel = styled.span`
  text-transform: uppercase;
  font-size: 0.85rem;
`;

export const DownArrow = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  outline: none;
  background: none;
  border: none;
  transform: ${({ active }) => (active ? "rotate(180deg)" : "rotate(0)")};
`;

export const MixerRoundButton = styled.button`
  outline: none;
  /* background-color: black; */
  background-color: #a8a9ad;
  color: white;
  height: 1.2rem;
  width: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  font-size: 0.85rem;
  font-weight: bold;

  &:disabled {
    background-color: #ddd;
    color: #000;
    pointer-events: none;
  }

  -webkit-print-color-adjust: exact;
  print-color-adjust: exact; /* Firefox 97+, Safari 15.4+ */
`;
