import { FlexBox, Text } from "@styles";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaVimeoV, FaYoutube } from "react-icons/fa";
import styled from "styled-components";
import { resources } from "./data";
import { brandLinks } from "../Navbar/data";

const FooterFlex = styled(FlexBox)`
  padding: 0.75rem;
  gap: 0.2rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact; /* Firefox 97+, Safari 15.4+ */

  * {
    font-size: 0.9rem;
  }
`;

const Divider = styled.span`
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-block: 0.2rem;

  @media print {
    display: none;
  }

  @media (max-width: 1075px) {
    display: none;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: #fff;

  &:not(:last-child)::after {
    content: "|";
    margin-left: 0.5rem;
  }
`;

const CustomFlexBox = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1075px) {
    flex-direction: column;
    gap: 1rem;
  }

  @media print {
    display: none;
  }
`;

const FinalFooterLinks = styled(FlexBox)`
  @media print {
    display: none;
  }
`;

const Footer = () => {
  const {
    site: {
      siteMetadata: { copyright, social },
    },
  } = useStaticQuery(graphql`
    query Footer {
      site {
        siteMetadata {
          copyright
          social {
            facebook
            instagram
            linkedin
            vimeo
            youtube
          }
        }
      }
    }
  `);

  const socials = [
    { name: "Facebook", href: social.facebook, icon: <FaFacebookF size={20} /> },
    { name: "Instagram", href: social.instagram, icon: <FaInstagram size={20} /> },
    { name: "Vimeo", href: social.vimeo, icon: <FaVimeoV size={20} /> },
    { name: "Youtube", href: social.youtube, icon: <FaYoutube size={20} /> },
    { name: "Linkedin", href: social.linkedin, icon: <FaLinkedinIn size={20} /> },
  ];

  return (
    <FooterFlex direction="column" backgroundColor="#000" color="#fff">
      <CustomFlexBox>
        <FlexBox justifyContent="center" flexWrap>
          {brandLinks
            .filter((bl) => bl.showInFooter)
            .map(({ href, label }) => (
              <Link key={label} href={href} target="_blank" referrerPolicy="no-referrer">
                <Text size={1.05} as="span" pre>
                  {label}
                </Text>
              </Link>
            ))}
        </FlexBox>

        <FlexBox as="ul" gap={1}>
          {socials.map(({ name, href, icon }) => (
            <FlexBox as="li" key={name}>
              <Link href={href} referrerPolicy="no-referrer" aria-label={name}>
                {icon}
              </Link>
            </FlexBox>
          ))}
        </FlexBox>
      </CustomFlexBox>

      <Divider />

      <FlexBox justifyContent="space-between" alignItems="center" gap={1} columnOnMobile={767}>
        <FinalFooterLinks flexWrap justifyContent="center">
          {resources.map(({ href, label }) => (
            <Link key={label} href={href} referrerPolicy="no-referrer">
              <Text size={1.05} as="span" pre>
                {label}
              </Text>
            </Link>
          ))}
        </FinalFooterLinks>

        <Text size={0.9} center>
          {copyright}
        </Text>
      </FlexBox>
    </FooterFlex>
  );
};

export default Footer;
