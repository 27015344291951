import React, { useState, FormEvent } from "react";
import styled from "styled-components";
import { FlexBox, Text } from "@styles";
import { FaSearch } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { Brand } from "@typed/categories";
import { BrandConfig } from "@constants/common";
import { navigate } from "gatsby";

import SearchBar from "@components/Search/SearchBar";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { LinkEntry, getBrandedLinks, mainLinks } from "./data";
import UserMenu from "./UserMenu";

const Container = styled.nav<{ $bgColor?: string }>`
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
`;

const Header = styled.div`
  display: flex;
  /* width: min(100% - 2rem, 1440px); */
  margin-inline: auto;
  align-items: right;
  justify-content: space-between;

  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact; /* Firefox 97+, Safari 15.4+ */
  }
`;

const LogoBox = styled.div<{ $bgColor?: string }>`
  @media (max-width: 1000px) {
    background: linear-gradient(-57.75deg, #000000 32px, ${(props) => props.$bgColor} 32px);
  }
  @media (min-width: 1000px) {
    background: ${(props) => props.$bgColor};
  }

  display: flex;
  flex: auto;
  align-items: center;
`;

const Logo = styled.img`
  height: 60px;
  width: 100%;
`;

const SearchBackdrop = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
`;

const SearchForm = styled.div`
  position: absolute;
  top: 64px;
  min-width: 300px;
  right: 0;
  background-color: #000;
  padding: 0.5rem;
`;

export const StoreLocator = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  padding-bottom: 2px;
  margin-right: -4px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export type MenuData = {
  bgColor?: string;
  brandedLinks: LinkEntry[];
  gacLinks: LinkEntry[];
};

const Navbar = ({ brand }: { brand: Brand }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const { brandLogo, bgColor, logoAlt, url } = BrandConfig[brand?.id ?? "brands-1"];
  const [searchValue, setSearchValue] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);

  const brandedLinks = !brand || brand.code === "gac" ? [] : [...getBrandedLinks(brand?.code ?? "gac")];
  const gacLinks = !brand || brand.code === "gac" ? [...getBrandedLinks(brand?.code ?? "gac"), ...mainLinks] : [...mainLinks];

  const menuData: MenuData = { bgColor, brandedLinks, gacLinks };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    if (searchValue !== "") {
      const value = searchValue;
      navigate(`/search?query=${value.replace(" ", "+")}`);
      setSearchValue("");
      setMobileNavOpen(false);
    }
  };

  return (
    <Container $bgColor={bgColor}>
      <Header>
        <LogoBox $bgColor={bgColor}>
          <a href={url}>
            <Logo src={brandLogo} alt={logoAlt} />
          </a>
        </LogoBox>

        <Desktop menuData={menuData} />

        <FlexBox gap={0.75} flex-wrap={false} alignItems="center" backgroundColor="#000000">
          <FlexBox
            as="a"
            hideMobile={1000}
            href="/store-locator"
            aria-label="Store locator"
            style={{ textDecoration: "none", color: "white" }}
          >
            <StoreLocator>
              <Text hideMobile={1000}>Find a store</Text>
              <MdLocationPin size={26} />
            </StoreLocator>
          </FlexBox>

          <FlexBox style={{ position: "relative" }} hideMobile={1000}>
            <FaSearch size={20} onClick={() => setSearchOpen(!searchOpen)} />
            {searchOpen ? (
              <SearchBackdrop onClick={() => setSearchOpen(false)}>
                <SearchForm onClick={(e) => e.stopPropagation()}>
                  <SearchBar handleSearch={handleSearch} searchInput={searchValue} setSearchInput={setSearchValue} />
                </SearchForm>
              </SearchBackdrop>
            ) : null}
          </FlexBox>

          <UserMenu />

          <Mobile
            menuData={menuData}
            isOpen={mobileNavOpen}
            setOpen={setMobileNavOpen}
            search={{ handleSearch, searchValue, setSearchValue }}
          />
        </FlexBox>
      </Header>
    </Container>
  );
};

export default Navbar;
