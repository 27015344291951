import React from "react";
import styled from "styled-components";
import { FaCaretDown } from "react-icons/fa";
import Link from "@components/Link";
import { Text } from "@styles";
import { LinkEntry } from "../data";

const LinkText = styled(Text)`
  border-radius: var(--borderRadius);
  font-weight: 200;
  padding: 0.5rem 0rem;

  &:hover {
    background-color: #42424280;
    font-weight: 500;
  }
`;

const ParentStateToggle = styled.input`
  display: none;
`;

const ChildItems = styled.ul`
  display: flex;
  flex-direction: column;
  /* gap: 0.75rem; */
  padding: 0rem 0rem 0rem 0.5rem;

  display: none;
`;

const Label = styled.label<{ $topLevel: boolean }>`
  /* text-decoration: underline; */
  display: flex;
  justify-content: space-between;
  text-transform: ${(props) => props.$topLevel && "uppercase"};
  align-items: center;
  font-weight: 200;
  padding: 0.5rem 0rem;

  &:hover {
    background-color: #42424280;
    font-weight: 500;
    cursor: pointer;
  }
`;

const Arrow = styled(FaCaretDown)`
  size: 18pts;
  margin-left: 0.5rem;
  transition: all 0.5s;
  transform: rotate(0);
`;

export const MobileLinkParent = styled.li<{ $bgColor?: string; $topLevel: boolean }>`
  background-color: ${(props) => props.$bgColor || "transparent"};
  display: flex;
  flex-direction: column;

  border-bottom: ${(props) => props.$topLevel && "1px solid white;"};

  ${ParentStateToggle}:checked ~ ${ChildItems} {
    display: flex;
  }

  ${ParentStateToggle}:checked ~ ${Label} {
    ${Arrow} {
      transform: rotate(180deg);
    }
  }
`;

const MenuTopLevel = ({
  item,
  setOpen,
  bgColor,
  topLevel,
}: {
  item: LinkEntry;
  setOpen: (_value: boolean) => void;
  bgColor?: string;
  topLevel: boolean;
}) => (
  <MobileLinkParent $topLevel={topLevel} $bgColor={bgColor}>
    {item.href ? (
      <Link to={item.href} key={item.label}>
        <LinkText style={topLevel ? { textTransform: "uppercase" } : {}} onClick={() => setOpen(false)}>
          {item.element || item.label || ""}
        </LinkText>
      </Link>
    ) : (
      <>
        <ParentStateToggle type="checkbox" id={item.label} />
        <Label htmlFor={item.label} $topLevel={topLevel}>
          {item.element || item.label || ""}
          <Arrow />
        </Label>

        <ChildItems>
          {(item.children ?? []).map((itemx) => (
            <MenuTopLevel item={itemx} key={`${item.label}-${itemx.label}`} setOpen={setOpen} topLevel={false} />
          ))}
        </ChildItems>
      </>
    )}
  </MobileLinkParent>
);

export default MenuTopLevel;
