import React, { Dispatch, FormEvent, SetStateAction } from "react";
import styled from "styled-components";
import { FlexBox } from "@styles";
import SearchBar from "@components/Search/SearchBar";
import Hamburger from "./Hamburger";
import MenuTopLevel from "./MenuTopLevel";

import { MenuData } from "..";

const Container = styled.ul<{ $bgColor?: string; isOpen: boolean }>`
  position: fixed;
  background-color: ${(props) => props.$bgColor || "#000000"};
  height: calc(100vh - 64px);
  top: 4rem;
  right: 0;
  z-index: 999;
  width: min(75%, 25rem);
  display: none;
  flex-direction: column;
  padding: 0rem 0.5rem 0rem 0.5rem;

  transform: ${({ isOpen }) => (isOpen ? `translate(0)` : `translateX(100vw)`)};
  transition: transform 0.8s ease-in-out;
  overflow-y: auto;
  font-weight: normal;

  @media (max-width: 1000px) {
    display: flex;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--textColor);
  }

  @media print {
    display: none;
  }
`;
// const sortOrder = ["Search", "Products", "Brands", "Store Locator", "Resources", "Community", "Contact", "About Us"];

const Mobile = ({
  menuData: { brandedLinks, gacLinks },
  isOpen,
  setOpen,
  search: { handleSearch, searchValue, setSearchValue },
}: {
  menuData: MenuData;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  search: {
    handleSearch: (_e: FormEvent) => void;
    searchValue: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
  };
}) => {
  // Merge the brandedLinks and gacLinks arrays
  const combinedLinks = [...(brandedLinks ?? []), ...gacLinks]
    .filter((l) => l.mobileSort)
    .sort((a, b) => (a?.mobileSort ?? 0) - (b?.mobileSort ?? 0));

  return (
    <FlexBox>
      <Hamburger isOpen={isOpen} toggleMenu={setOpen} />

      <Container isOpen={isOpen}>
        <li>
          <SearchBar handleSearch={handleSearch} searchInput={searchValue} setSearchInput={setSearchValue} />
        </li>

        {combinedLinks.map((item) => (
          <MenuTopLevel key={item.label} setOpen={setOpen} item={item} topLevel bgColor={item.bgColor} />
        ))}
      </Container>
    </FlexBox>
  );
};

export default Mobile;
