import React from "react";
import styled from "styled-components";
import { FlexBox } from "@styles";
import DropMenuItem from "./DropMenuItem";
import { MenuData } from "..";

const Container = styled.ul<{ $bgColor?: string }>`
  height: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.75rem; //This makes up for the .75 rem gap on the last item
  gap: 0.75rem;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const TransitionContainer = styled(Container)<{ $bgColor?: string }>`
  background: linear-gradient(-57.75deg, #000000 32px, ${(props) => props.$bgColor} 32px);
  width: 100px;
`;

const Desktop = ({ menuData: { brandedLinks, bgColor, gacLinks } }: { menuData: MenuData }) => (
  <FlexBox backgroundColor="#000000">
    {brandedLinks?.length ? (
      <TransitionContainer $bgColor={bgColor}>
        {brandedLinks
          .filter((l) => l.desktopSort)
          .sort((l1, l2) => (l1.desktopSort ?? 0) - (l2.desktopSort ?? 0))
          .map((item) => (
            <DropMenuItem item={item} bgColor={bgColor} key={item.label} />
          ))}
      </TransitionContainer>
    ) : null}

    <Container>
      {gacLinks
        .filter((l) => l.desktopSort)
        .sort((l1, l2) => (l1.desktopSort ?? 0) - (l2.desktopSort ?? 0))
        .map((item) => (
          <DropMenuItem item={item} key={item.label} />
        ))}
    </Container>
  </FlexBox>
);

export default Desktop;
