import React from "react";
import styled from "styled-components";

const CtaButton = styled.button`
  position: relative;
  height: 40px;
  width: 30px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  outline: none;
  background: none;
  display: none;
  justify-content: center;
  align-items: center;
  border: none;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

const Icon = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: 75%;
  height: 3px;
  background-color: #fff;
  transition: all 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);

  &:nth-of-type(1) {
    transform: ${({ isOpen }) => (isOpen ? `rotate(40deg)` : `translateY(-8px)`)};
    animation-delay: 100ms;
  }

  &:nth-of-type(2) {
    animation-delay: 250ms;
    opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
  }

  &:nth-of-type(3) {
    transform: ${({ isOpen }) => (isOpen ? `rotate(-40deg)` : `translateY(8px)`)};
    animation-delay: 250ms;
  }
`;

const Hamburger = ({ isOpen, toggleMenu }: { isOpen: boolean; toggleMenu: any }) => (
  <CtaButton onClick={() => toggleMenu(!isOpen)} aria-label="Toggle Menu">
    {[1, 2, 3].map((key) => (
      <Icon key={key} isOpen={isOpen} />
    ))}
  </CtaButton>
);

export default Hamburger;
