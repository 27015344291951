import React, { ReactElement, createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { IUser, RegistrationData } from "@contexts/AuthContext/types";
import { getDbUser } from "./functions/getDbUser";
import { gacRegister } from "./functions/gacRegister";
import { setRegistrationData } from "./functions/setRegistrationData";
import { getRegistrationData } from "./functions/getRegistrationData";
import { getInternalToken } from "./functions/getInternalToken";
import { getEmailFromCurrentUser } from "./functions/getEmailFromCurrentUser";

type AuthContextType = {
  getRegistrationData: () => RegistrationData | null;
  cognitoUserPool: CognitoUserPool;

  cognitoUser: CognitoUser | null;
  setCognitoUser: (user: CognitoUser | null) => void;

  setGacUser: (user: IUser | null) => void;
  gacUser: IUser | null;
  getAuthToken: () => Promise<string | null>;
  loadUser: () => Promise<IUser | null>;
};

const AuthContext = createContext<AuthContextType>({
  getRegistrationData: () => null,
  cognitoUserPool: {} as CognitoUserPool,
  cognitoUser: null,
  setCognitoUser: () => null,
  setGacUser: () => null,
  gacUser: null,
  getAuthToken: async () => null,
  loadUser: async () => null,
});

export const useAuth = () => useContext(AuthContext);

if (!process.env.GATSBY_AWS_USER_CLIENT_ID) throw new Error("Missing key in env:  GATSBY_AWS_USER_CLIENT_ID ");
if (!process.env.GATSBY_AWS_USER_POOL_ID) throw new Error("Missing key in env:  GATSBY_AWS_USER_POOL_ID ");

const gacUserPool = new CognitoUserPool({
  UserPoolId: process.env.GATSBY_AWS_USER_POOL_ID,
  ClientId: process.env.GATSBY_AWS_USER_CLIENT_ID,
});

export const AuthProvider = ({ children }: { children: ReactElement | ReactElement[] }) => {
  const [gacUser, setGacUserInternal] = useState<IUser | null>(null);
  const [cognitoUser, setCognitoUserInternal] = useState<CognitoUser | null>(gacUserPool.getCurrentUser());

  // useReducer(gacUserReducer, { isLoading: false });

  // const cognitoUser = gacUserPool.getCurrentUser();

  const setGacUser = (user: IUser | null) => {
    setGacUserInternal(user);
    if (!user) {
      sessionStorage.removeItem("GACUser");
      return;
    }
    sessionStorage.setItem("GACUser", JSON.stringify(user));
  };

  const getAuthToken = useCallback(async () => {
    const expire = localStorage.getItem("GACTokenExpire");
    const savedToken = localStorage.getItem("GACToken");

    if (savedToken && expire && Number(expire) > Date.now()) return savedToken;

    return getInternalToken(cognitoUser, setGacUserInternal);
  }, [cognitoUser]);

  const loadUser = useCallback(async (): Promise<IUser | null> => {
    if (gacUser) return gacUser;

    const authtoken = await getAuthToken();

    // couldnt get authToken from cognito, user not logged in, ensure user is clear
    if (!authtoken) {
      setGacUser(null);
      return null;
    }

    const storedUser = sessionStorage.getItem("GACUser");

    // if user is stored in session, use that
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setGacUser(user);
      return user;
    }

    const registrationData = getRegistrationData();
    // if user is not stored in session, and no registration data, load user from db
    if (!registrationData) {
      const user = await getDbUser(authtoken);
      setGacUser(user);
      return user;
    }

    const tokenEmail = await getEmailFromCurrentUser(cognitoUser);

    // if registration data exists, update user with registration data
    if (registrationData && registrationData.email === tokenEmail) {
      const tempUser = await gacRegister(authtoken, registrationData);
      setGacUser(tempUser);

      setRegistrationData(null);
      return tempUser;
    }

    // If registration data exists but for different email, skip registration data update, and just load the user from db
    // This would rarely happen.
    const tempUser = await getDbUser(authtoken);
    setGacUser(tempUser);
    return tempUser;
  }, [cognitoUser, gacUser]);

  // load user on mount
  useEffect(() => {
    console.log("cognitoUser changed: loading user");
    loadUser();
  }, [cognitoUser, gacUser]);

  const value = useMemo(
    () => ({
      getRegistrationData,
      cognitoUserPool: gacUserPool,
      cognitoUser,
      setCognitoUser: (user: CognitoUser | null) => setCognitoUserInternal(user),
      setGacUser: (user: IUser | null) => setGacUser(user),
      gacUser,
      getAuthToken,
      loadUser,
    }),
    [gacUser, cognitoUser],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
