import { FlexBox } from "@styles";
import React, { Suspense, useState } from "react";
import { FaUser } from "react-icons/fa";
import styled from "styled-components";

const OverlayScreen = styled.div`
  position: fixed;
  inset: 0;
`;

const DropdownMenu = styled.div`
  position: absolute;
  width: 250px;
  background-color: #000;
  right: -0.75rem;
  top: 2.6rem;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  padding: 0.5rem 1rem;
  gap: 1rem;

  @media (max-width: 1000px) {
    right: -2.6rem;
  }
`;

const UserDetail = React.lazy(() => import("./UserDetail"));

const UserMenu = () => {
  const [loginOpen, setLoginOpen] = useState(false);

  return (
    <FlexBox relative>
      <FaUser size={20} onClick={() => setLoginOpen(!loginOpen)} />

      {loginOpen ? <OverlayScreen onClick={() => setLoginOpen(false)} /> : null}

      {loginOpen ? (
        <DropdownMenu>
          <Suspense fallback={<div>Loading...</div>}>
            <UserDetail closeMenu={() => setLoginOpen(false)} />
          </Suspense>
        </DropdownMenu>
      ) : null}
    </FlexBox>
  );
};

export default UserMenu;
