import React from "react";
import styled from "styled-components";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import Link from "@components/Link";
import { Text } from "@styles";
import { LinkEntry } from "../data";

const Dropdown = styled.ul<{ $bgColor?: string; $left?: string }>`
  position: absolute;
  background-color: ${(props) => props.$bgColor ?? "#000"};
  top: calc(100%);
  left: ${(props) => (props.$left ? props.$left : "-1rem")};
  width: 225px;
  list-style: none;
  display: none;
  flex-direction: column;
  z-index: 999;
`;

const TopLevel = styled.li`
  height: 100%;

  display: flex;
  align-items: center;
  gap: 0rem;
  white-space: nowrap;
  position: relative;
  cursor: pointer;

  &:hover > ${Dropdown} {
    display: flex;
  }
`;

const SubMenu = styled.ul<{ width?: string; $bgColor?: string }>`
  position: absolute;
  top: 0;
  left: 100%;
  background-color: ${(props) => props.$bgColor ?? "#000"};
  display: none;
  flex-direction: column;

  width: ${(props) => (props.width ? props.width : "100%")};
`;

const DropdownItem = styled.li`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  white-space: pre-wrap;
  &:hover {
    background-color: #42424280;
  }

  &:hover > ${SubMenu} {
    display: flex;
  }
`;

const DropMenuItem = ({ item, bgColor = "#000000" }: { item: LinkEntry; bgColor?: string }) => (
  <TopLevel key={item.label}>
    <Text as="span" lineHeight={3.9}>
      {item.element || item.label || ""}
    </Text>
    {!item.element && <FaCaretDown size={18} />}

    <Dropdown $bgColor={bgColor} key={item.label} $left={item.label === "Search" ? "-10rem" : "-1rem"}>
      {item.children &&
        item.children.map((itemx) => {
          const hasChild = Boolean(itemx.children);
          return (
            <DropdownItem key={itemx.label}>
              <Link to={itemx.href ?? "#"} customStyle={{ padding: "0.5rem 1rem" }}>
                {itemx.element || itemx.label || ""}
              </Link>

              {hasChild && (
                <>
                  <FaCaretRight size={18} style={{ marginRight: "0.5rem" }} />
                  <SubMenu
                    width={itemx.label === "Educator Resources" || itemx.label === "Paint Mixers" ? "150px" : undefined}
                    $bgColor={bgColor}
                  >
                    {itemx.children?.map(({ label, href }) => (
                      <DropdownItem key={label}>
                        {href && (
                          <Link to={href} customStyle={{ padding: "0.5rem 1rem" }}>
                            {label}
                          </Link>
                        )}
                      </DropdownItem>
                    ))}
                  </SubMenu>
                </>
              )}
            </DropdownItem>
          );
        })}
    </Dropdown>
  </TopLevel>
);

export default DropMenuItem;
