import React, { FC, ReactNode } from "react";
import styles, { createGlobalStyle } from "styled-components";
import { Brand } from "@typed/categories";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "@contexts/AuthContext";
import Navbar from "./Navbar";
import Footer from "./Footer";

type LayoutProps = {
  children: ReactNode;
  brand: Brand;
};

const styled = { createGlobalStyle };

export const GlobalStyles = styled.createGlobalStyle`
  /* roboto-condensed-regular - latin */
  @font-face {
    font-display: optional; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/roboto-condensed-v27-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }

  @font-face {
    font-family: "Roboto Condensed-fallback";
    size-adjust: 89%;
    ascent-override: 110%;
    src: local("Arial");
  }

  :root {
    --borderRadius: 4px;
    --textColor: #979797;
    --blueColor: #12b8f6;
  }

  html {
    scroll-behavior: smooth;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto Condensed", "Roboto Condensed-fallback";
  }

  ::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.45rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 0.5rem;
  }

  section {
    scroll-margin-top: 4rem;
  }

  img {
    border-radius: 4px;
  }
`;

const Page = styles.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Layout: FC<LayoutProps> = ({ children, brand }) => (
  <AuthProvider>
    <GlobalStyles />
    <Page>
      <Navbar brand={brand} />
      {children}
      <Footer />
    </Page>

    <Toaster />
  </AuthProvider>
);

export default Layout;
