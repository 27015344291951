export const ME = `query Me {
  me {
      id
      firstName
      lastName
      email
      website
      facebookLink 
      instagramLink 
      linkedInLink 
      addresses {
        city
        country
        id
        state
        streetName
        zip
      }
      mixes {
        id
        colorName
        hex
        brand
        part1
        part2
        part3
        part4
        tube1
        tube2
        tube3
        tube4
    }
     palettes {
      brand
      colors
      id
      paletteName
    }
  }
}`;

export const DELETE_USER = `query DeleteUser {
  deleteUser 
}
`;

export const REGISTER = `query Register($firstName: String!, $lastName: String!, $email: String!) {
    register(firstName: $firstName, lastName: $lastName, email: $email) {
      email
      firstName
      id
      lastName
        mixes {
        id
        colorName
        hex
        brand
        part1
        part2
        part3
        part4
        tube1
        tube2
        tube3
        tube4
      }
    }
  }
`;

export const UPDATE_PROFILE = `query UpdateUser ($addressId: String, $city: String, $country: String, $state: String, $streetName: String, $website: String, $firstName: String, $lastName: String, $email: String, $facebookLink: String, $instagramLink: String, $linkedInLink: String, $zip: String) {
  updateUser(
      addressId: $addressId
      city: $city
      country: $country
      state: $state
      streetName: $streetName
      website: $website
      firstName: $firstName
      lastName: $lastName
      email: $email
      facebookLink: $facebookLink
      instagramLink: $instagramLink
      linkedInLink: $linkedInLink
      zip: $zip
  ) {
      email
      firstName
      id
      lastName
      website
      addresses {
          city
          country
          id
          state
          streetName
          zip
      }
      linkedInLink
      facebookLink
      instagramLink
  }
}`;

export const SEARCH_KEYWORD = `
  query SearchByKeyword($keyword: String!) {
    searchByKeyword(keyword: $keyword) {
      index
      id
      score
      fields {
        filters {
          groupName
          values
        }
        description
        path
        title
        type
        featuredImageUrl
      }
      type
    }
  }
`;

export const SAVE_MIX = `query SaveMix ($colorName: String!, $hex: String!, $part1: String, $part2: String, $part3: String, $part4: String, $tube1: String, $tube2: String, $tube3: String, $tube4: String, $brand: String!) {
  saveMix(
      colorName: $colorName
      hex: $hex
      part1: $part1
      part2: $part2
      part3: $part3
      part4: $part4
      tube1: $tube1
      tube2: $tube2
      tube3: $tube3
      tube4: $tube4
      brand: $brand
  ) {
        id
        colorName
        hex
        brand
        part1
        part2
        part3
        part4
        tube1
        tube2
        tube3
        tube4
     
  }
}
`;

export const DELETE_USER_MIX = `query DeleteMix($mixId: String!) {
    deleteMix(mixId: $mixId) 
  }
`;

export const SAVE_PALETTE = `query SavePalette ($brand: String!, $colors: [String!]!, $paletteName: String!) {
  savePalette(brand: $brand, colors: $colors, paletteName: $paletteName) {
    brand
    colors
    id
    paletteName
  }
}
`;

export const DELETE_PALETTE = `query DeletePalette($paletteId: String!) {
  deletePalette(paletteId: $paletteId) 
}`;

export const UPDATE_PALETTE = `query UpdatePalette($brand: String!, $colors: [String!]!, $id: String!, $paletteName: String!) {
  savePalette(brand: $brand, colors: $colors, id: $id, paletteName: $paletteName) {
      brand
      colors
      id
      paletteName
  }
}
`;

export const CREATE_EVENT_REGISTRANT = `query createEventRegistrant($eventId: String!, $type: String!, $numberOfAttendees: Int!, $description: String, $email: String, $firstName: String, $lastName: String) {
  createEventRegistrant(eventId: $eventId, type: $type, numberOfAttendees: $numberOfAttendees, description: $description, email: $email, firstName: $firstName, lastName: $lastName) {
    pk
    sk
    type
    numberOfAttendees
    userId
    description
    eventId
  }
}`;

export const GET_USER = `query GetUser ($id: String!) {
  getUser (id: $id) {
      id
      firstName
      lastName
      email
      website
      facebookLink 
      instagramLink 
      linkedInLink 
      addresses {
        city
        country
        id
        state
        streetName
        zip
      }
      mixes {
        id
        colorName
        hex
        brand
        part1
        part2
        part3
        part4
        tube1
        tube2
        tube3
        tube4
    }
     palettes {
      brand
      colors
      id
      paletteName
    }
  }
}`;
