import React from "react";
import styled from "styled-components";

import { FaSearch, FaTimes } from "react-icons/fa";

const SearchBox = styled.form`
  position: relative;
  max-width: 300px;
  width: 100%;
  display: flex;
  border: 1px solid black;
  border-radius: 0.25rem;
`;

const SearchIconBox = styled.button`
  background: black;
  color: white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding-inline: 10px;
  cursor: pointer;
  border: none;
`;

const SearchInput = styled.input`
  border: none;
  width: 100%;
  padding: 0.5rem;
  outline: none;
`;

const ClearIcon = styled(FaTimes)`
  position: absolute;
  right: 3rem;
  top: 25%;
  color: gray;
  cursor: pointer;
`;

const SearchBar = ({
  searchInput,
  setSearchInput,
  handleSearch,
  placeholder = "Search",
}: {
  searchInput: string;
  setSearchInput: (_value: string) => void;
  handleSearch: (_value: React.SyntheticEvent) => void;
  placeholder?: string;
}) => (
  <SearchBox onSubmit={handleSearch}>
    <SearchInput value={searchInput} placeholder={placeholder} style={{ width: "100%" }} onChange={(e) => setSearchInput(e.target.value)} />
    {searchInput !== "" && <ClearIcon onClick={() => setSearchInput("")} />}
    <SearchIconBox type="submit" aria-label="Search Submission">
      <FaSearch />
    </SearchIconBox>
  </SearchBox>
);

export default SearchBar;
