import React from "react";
import { BrandConfigByCode } from "@constants/common";

export interface LinkEntry {
  label?: string;
  element?: React.ReactNode;
  brandCode?: string;
  href?: string;
  bgColor?: string;
  children?: LinkEntry[];
  mobileSort?: number; // parent level sort order for mobile, note: no sort, means it wont show
  desktopSort?: number; // parent level sort order for desktop note: no sort, means it wont show
  showInFooter?: boolean;
}

export const brandLinks: LinkEntry[] = [
  { label: "Golden Artist Colors", brandCode: "gac", href: "/", showInFooter: true },

  // { label: "Golden Artist Acrylics", href: "https://www.goldenpaints.com/products" },
  { label: "Golden Artist Acrylics", brandCode: "gaa", href: "/products/golden-artist-acrylics", showInFooter: true },

  { label: "Williamsburg Artist Oil Colors", brandCode: "wb", href: "/products/williamsburg-artist-oil-colors", showInFooter: true },
  { label: "QoR Artist Watercolors", brandCode: "qor", href: "/products/qor-artist-watercolors", showInFooter: true },
  { label: "PanPastel Artist Pastels", brandCode: "pp", href: "https://panpastel.com/", showInFooter: true },
  { label: "Golden Paintworks", brandCode: "gpw", href: "https://goldenpaintworks.com", showInFooter: true },
  { label: "Merch", href: "https://shop.goldenartistcolors.com/merchandise.html", showInFooter: false },
];

const brandedLinks: LinkEntry[] = [
  // GAC Brands
  { label: "Brands", brandCode: "gac", mobileSort: 2, desktopSort: 1, children: [...brandLinks.filter((l) => l.brandCode !== "gac")] },

  // GAA Products
  {
    label: "Products",
    brandCode: "gaa",
    bgColor: BrandConfigByCode.gaa.bgColor,
    mobileSort: 2,
    desktopSort: 1,
    children: [
      {
        label: "Colors",
        children: [
          { label: "Heavy Body Color", href: "/products/golden-artist-acrylics/heavy-body" },
          { label: "OPEN Slow-Drying Color", href: "/products/golden-artist-acrylics/open" },
          { label: "SoFlat Matte Color", href: "/products/golden-artist-acrylics/soflat" },
          { label: "Fluid Color", href: "/products/golden-artist-acrylics/fluid" },
          { label: "High Flow Color", href: "/products/golden-artist-acrylics/high-flow" },
          { label: "Fluorescent & Phosphorescent Colors", href: "/products/golden-artist-acrylics/fluorescent-phosphorescent-colors" },
          { label: "Interference & Iridescent Colors", href: "/products/golden-artist-acrylics/interference-iridescent-colors" },
        ],
      },
      { label: "Additives", href: "/products/golden-artist-acrylics/additives" },
      { label: "Fluid Mediums", href: "/products/golden-artist-acrylics/fluid-mediums" },
      { label: "Gel & Paste Mediums", href: "/products/golden-artist-acrylics/gel-paste-mediums" },
      { label: "Gesso & Grounds", href: "/products/golden-artist-acrylics/gesso-grounds" },
      { label: "Varnishes & Top Coats ", href: "/products/golden-artist-acrylics/varnishes-top-coats" },
      { label: "Sets", href: "/products/golden-artist-acrylics/sets" },
      { label: "Custom Products", href: "/products/golden-artist-acrylics/custom-products" },
      { label: "Other Brands/Media", children: [...brandLinks.filter((l) => l.brandCode !== "gaa")] },
    ],
  },

  // WB Products
  {
    label: "Products",
    brandCode: "wb",
    bgColor: BrandConfigByCode.wb.bgColor,
    mobileSort: 2,
    desktopSort: 1,
    children: [
      { label: "Oil Colors", href: "/products/williamsburg-artist-oil-colors/oil-colors" },
      { label: "Mediums & Additives", href: "/products/williamsburg-artist-oil-colors/mediums-additives" },
      { label: "Grounds", href: "/products/williamsburg-artist-oil-colors/grounds" },
      { label: "Sets", href: "/products/williamsburg-artist-oil-colors/sets" },
      {
        label: "Other Brands/Media",
        children: [...brandLinks.filter((l) => l.brandCode !== "wb")],
      },
    ],
  },
  // Qor Products
  {
    label: "Products",
    brandCode: "qor",
    bgColor: BrandConfigByCode.qor.bgColor,
    mobileSort: 2,
    desktopSort: 1,
    children: [
      { label: "Watercolors", href: "/products/qor-artist-watercolors/watercolors" },
      { label: "Mediums & Grounds", href: "/products/qor-artist-watercolors/mediums-grounds" },
      { label: "Sets", href: "/products/qor-artist-watercolors/sets" },
      { label: "Other Brands/Media", children: [...brandLinks.filter((l) => l.brandCode !== "qor")] },
    ],
  },
  {
    label: "Products",
    brandCode: "pp",
    bgColor: BrandConfigByCode.pp.bgColor,
    mobileSort: 2,
    desktopSort: 1,
    children: [
      { label: "Colors", href: "/products/panpastel-artist-pastels/colors" },
      { label: "Mediums", href: "/products/panpastel-artist-pastels/mediums" },
      { label: "Sofft Tools ", href: "/products/panpastel-artist-pastels/sofft-tools" },
      { label: "Sets & Kits", href: "/products/panpastel-artist-pastels/sets" },
      { label: "Other Brands/Media", children: [...brandLinks.filter((l) => l.brandCode !== "qor")] },
    ],
  },
];

export const mainLinks: LinkEntry[] = [
  {
    label: "Find a Store",
    href: "/store-locator",
    mobileSort: 4,
  },

  {
    label: "About Us",
    mobileSort: 7,
    desktopSort: 2,
    children: [
      { label: "Product & Company News", href: "/news" },
      {
        label: "History",
        children: [
          { label: "Golden Company History", href: "/history" },
          { label: "Science of Qor Watercolor", href: "/science-of-qor-watercolors" },
          { label: "History of Williamsburg Oils", href: "/history-of-williamsburg-artist-oils" },
        ],
      },
      { label: "Team", href: "/team" },
      {
        label: "Values",
        children: [
          { label: "Employee Ownership", href: "/esop" },
          { label: "Equity & Inclusion", href: "/equity-inclusion" },
          { label: "Social Responsibility", href: "/social-responsibility" },
        ],
      },
      { label: "Company FAQ", href: "/faq" },
      { label: "Paint Stories Podcast", href: "https://paint-stories-with-mark-golden.simplecast.com" },
    ],
  },
  {
    label: "Community",
    mobileSort: 5,
    desktopSort: 3,
    children: [
      { label: "Factory Tours", href: "/factory-tours" }, // href: "/tour-signup"
      { label: "Calendar of Events", href: "https://goldenpaints.com/all_event" }, // /events
      { label: "Event Registration", href: "/event-registration" },
      { label: "Seconds Program", href: "/seconds-program" },
      { label: "GOLDEN Certified Educator Programs", href: "/certified-educator-programs" },
      { label: "The SAGG Gallery", href: "https://www.thesagg.org/" },
      { label: "The Sam & Adele Golden Foundation", href: "https://www.goldenfoundation.org/" },
    ],
  },
  {
    label: "Resources",
    mobileSort: 4,
    desktopSort: 4,
    children: [
      {
        label: "Paint Mixers",
        children: [
          { label: "Paint Mixer Intro", href: "/mixer-overview" },
          { label: "Acrylic Paint Mixer (NEW)", href: "/mixer/acrylic" },
          { label: "Oil Paint Mixer (NEW)", href: "/mixer/oil" },
          { label: "Original Acrylic Paint Mixer", href: "https://goldenpaints.com/mixer" },
        ],
      },
      { label: "Health & Safety", href: "/resources/category/health-and-safety" },
      { label: "Product & Application Information", href: "/resources" },
      { label: "Product Support", href: "/contact-us?dept=techsupport" },
      {
        label: "Educator Resources",
        children: [
          { label: "Request a Lecture Demo", href: "/contact-us?dept=education" },
          // { label: "Lesson Plans", href: "/resources/category/lesson-plans" },
          { label: "Seconds Request", href: "/seconds-program" },
        ],
      },
      { label: "Video Library", href: "/resources?resource-type=video" },
      { label: "Paint Articles", href: "/resources?resource-type=external-reference" },
      { label: "Conservation", href: "/conservation-resource-guide/" },
      { label: "Custom Lab", href: "/customlab-overview" },
    ],
  },
  {
    label: "Contact",
    mobileSort: 6,
    desktopSort: 5,
    children: [
      { label: "Receive Updates", href: "/conversation" },
      {
        label: "Job Opportunities",
        href: "/jobs",
      },
      { label: "Contact Us", href: "/contact-us" },
    ],
  },
];

export const getBrandedLinks = (brandCode: string): LinkEntry[] => [
  ...brandedLinks.filter((l) => !l.brandCode || l.brandCode === brandCode),
];
