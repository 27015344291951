import React, { CSSProperties } from "react";
import { Link as GatsbyLink } from "gatsby";

const Link = ({
  to = "#",
  color = "inherit",
  lightBlue = false,
  children,
  ariaLabel,
  customStyle,
  onClick,
  newTab = false,
}: {
  to: string;
  color?: string;
  lightBlue?: boolean;
  children: React.ReactNode;
  customStyle?: CSSProperties;
  ariaLabel?: string;
  onClick?: () => void;
  newTab?: boolean;
}) => {
  const styles = {
    color: lightBlue ? "#12b8f6" : color,
    textDecoration: "none",
    width: "100%",
    ...customStyle,
  };

  if (to.includes("http") || to.includes("mailto") || to.startsWith("tel") || newTab) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noreferrer"
        aria-label="{ariaLabel}"
        style={styles}
        onClick={() => {
          if (typeof window !== "undefined") (window as any)?.plausible?.("External-Link-Click", { props: { url: to } });
        }}
      >
        {children}
      </a>
    );
  }
  return (
    <GatsbyLink to={to} aria-label={ariaLabel} style={styles} onClick={onClick}>
      {children}
    </GatsbyLink>
  );
};

Link.defaultProps = {
  color: "inherit",
  lightBlue: false,
  customStyle: {},
};

export default Link;
