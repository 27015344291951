import { Tube, Tubes } from "@contexts/MixerContext";

export const DEPARTMENTS: { label: string; value: string }[] = [
  { label: "Product Use Questions", value: "techsupport" },
  { label: "Customer Services - Orders", value: "custservmgr" },
  { label: "US Sales", value: "sales" },
  { label: "Environment, Health, and Safety", value: "safety" },
  { label: "Custom Lab", value: "customlab" },
  { label: "Education", value: "education" },
  { label: "Marketing", value: "marketing" },
  { label: "Webmaster", value: "webmaster" },
];

export type DEPARTMENT = "techsupport" | "custservmgr" | "sales" | "safety" | "customlab" | "education" | "marketing" | "webmaster";

export const DEFAULT_IMAGE = "https://goldenhub8.goldenpaints.com/goldenhub8/assets/86hjfbxqz3k8o0w0";

export const PRODUCT_GROUP = "Product Group";
export const CURATED_CONTENT = "Curated Content";

export const DEFAULT_COLOR_VALUE: Tube = { r: 255, g: 255, b: 255, name: "", paintId: "", quantityHdths: 0 };
export const DEFAULT_EMPTY_TUBES: Tubes = {
  tube1: { r: 255, g: 255, b: 255, name: "", paintId: "", quantityHdths: 0 },
  tube2: { r: 255, g: 255, b: 255, name: "", paintId: "", quantityHdths: 0 },
  tube3: { r: 255, g: 255, b: 255, name: "", paintId: "", quantityHdths: 0 },
  tube4: { r: 255, g: 255, b: 255, name: "", paintId: "", quantityHdths: 0 },
};
export const DEFAULT_MIXED_COLORS = {
  rgb: { r: 255, g: 255, b: 255 },
  lab: { l: 100, a: 0, b: 0 },
  tint1_1: { r: 255, g: 255, b: 255 },
  tint1_3: { r: 255, g: 255, b: 255 },
  tint1_10: { r: 255, g: 255, b: 255 },
  tint3_1: { r: 255, g: 255, b: 255 },
  tint10_1: { r: 255, g: 255, b: 255 },
};

export const DEFAULT_NUMBERIC_ERROR = {
  RGB: "",
  CMYK: "",
};

type Config = {
  brandLogo: string;
  url: string;
  slug: string;
  logoAlt: string;
  bgColor: string;
  fullName: string;
  storeLocatorLogo?: string;
  quickMediumPrefix: string; // used to help display product groups in search
  code: string;
};

export const BrandConfig: { [key: string]: Config } = {
  "brands-1": {
    brandLogo: "/images/logos/GAC_2021_Lockup_W.svg",
    url: "/",
    slug: "golden-artist-colors",
    logoAlt: "Golden Artist Colors",
    bgColor: "#000000",
    fullName: "Golden Artist Colors",
    quickMediumPrefix: "GAC",
    code: "gac",
  },
  "brands-2": {
    brandLogo: "/images/logos/WB_2021_Lockup_W.svg",
    url: "/products/williamsburg-artist-oil-colors",
    slug: "williamsburg-artist-oil-colors",
    logoAlt: "Williamsburg Artist Oils",
    bgColor: "#6E182E",
    fullName: "Williamsburg Artist Oils",
    quickMediumPrefix: "Oil",
    code: "wb",
    storeLocatorLogo: "/images/store-locator/W_icon18x18.gif",
  },
  "brands-3": {
    brandLogo: "/images/logos/QoR_2021_Lockup_W.svg",
    url: "/products/qor-artist-watercolors",
    slug: "qor-artist-watercolors",
    logoAlt: "QoR Artist Watercolors",
    bgColor: "#182632",
    fullName: "QoR Artist Watercolors",
    quickMediumPrefix: "Watercolor",
    code: "qor",
    storeLocatorLogo: "/images/store-locator/Q_icon18x18.gif",
  },
  "brands-4": {
    brandLogo: "/images/logos/GACAcrylics_2021_Lockup_W.svg",
    url: "/products/golden-artist-acrylics",
    slug: "golden-artist-acrylics",
    logoAlt: "Golden Artist Acrylics",
    bgColor: "#312F2E",
    fullName: "Golden Artist Acrylics",
    quickMediumPrefix: "Acrylic",
    code: "gaa",
    storeLocatorLogo: "/images/store-locator/G_icon18x18.gif",
  },
  "brands-5": {
    brandLogo: "/images/logos/PPAP_w_lockup.svg",
    url: "/products/panpastel-artist-pastels",
    slug: "golden-artist-acrylics",
    logoAlt: "PanPastel Artist Pastels",
    bgColor: "#225522",
    fullName: "PanPastel Artist Pastels",
    quickMediumPrefix: "PanPastel",
    code: "pp",
    storeLocatorLogo: "/images/store-locator/P_icon18x18.gif",
  },
};

export const BrandConfigByCode: { [key: string]: Config } = {
  gac: BrandConfig["brands-1"],
  wb: BrandConfig["brands-2"],
  qor: BrandConfig["brands-3"],
  gaa: BrandConfig["brands-4"],
  pp: BrandConfig["brands-5"],
};

export const BrandMapByName: { [key: string]: string } = {
  "Williamsburg Handmade Oil Colors": "wb",
  "QoR Watercolors": "qor",
  Golden: "gaa",
};
